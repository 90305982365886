import Cookies from 'js-cookie';
import { SET_MP_AI_FILTER } from '../constants/action-types';
import { AI } from '../components/Pages/Incoming/constants';

export default function toggleAiStream(
  state = Cookies.get(AI) === 'true',
  action
) {
  switch (action.type) {
    case SET_MP_AI_FILTER:
      return action.payload;
    default:
      return state;
  }
}

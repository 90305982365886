const CREATE_CHAT_ALERT = 'CREATE_CHAT_ALERT';
const DELETE_CHAT_ALERT = 'DELETE_CHAT_ALERT';
const SET_CHATSTREAM = 'SET_CHATSTREAM';
const SET_VIEW_ALL_CHATS = 'SET_VIEW_ALL_CHATS';
const UPDATE_VIEW_ALL_CHATS = 'UPDATE_VIEW_ALL_CHATS';
const UPDATE_CHATSTREAM = 'UPDATE_CHATSTREAM';
const TOGGLE_CHATSTREAM = 'TOGGLE_CHATSTREAM';
const SET_CHATSTREAM_INPUT = 'SET_CHATSTREAM_INPUT';
const CLEAR_CHAT_INPUT = 'CLEAR_CHAT_INPUT';
const SET_MESSAGE = 'SET_MESSAGE';
const DELETE_MESSAGE = 'DELETE_MESSAGE';
const SET_MAP_ON_IMAGE_VIEW = 'SET_MAP_ON_IMAGE_VIEW';
const SET_FOCUSED_IMAGE = 'SET_FOCUSED_IMAGE';
const SET_MP_AI_FILTER = 'SET_MONITORING_PAGE_AI_FILTER';
const SET_MP_PAUSE = 'SET_MONITORING_PAGE_PAUSE';
const SET_MP_DETECTION_FILTER = 'SET_MONITORING_PAGE_DETECTION_FILTER';
const SET_MP_BACKLOG = 'SET_MONITORING_PAGE_BACKLOG';
const SET_MP_PICTURE_INSTRUCTIONS = 'SET_MONITORING_PAGE_PICTURE_INSTRUCTIONS';
const TOGGLE_FLAG_ALERTS = 'TOGGLE_FLAG_ALERTS';

module.exports = {
  SET_CHATSTREAM,
  CREATE_CHAT_ALERT,
  DELETE_CHAT_ALERT,
  SET_VIEW_ALL_CHATS,
  UPDATE_VIEW_ALL_CHATS,
  UPDATE_CHATSTREAM,
  TOGGLE_CHATSTREAM,
  SET_CHATSTREAM_INPUT,
  CLEAR_CHAT_INPUT,
  SET_MESSAGE,
  DELETE_MESSAGE,
  SET_MAP_ON_IMAGE_VIEW,
  SET_FOCUSED_IMAGE,
  SET_MP_AI_FILTER,
  SET_MP_BACKLOG,
  SET_MP_DETECTION_FILTER,
  SET_MP_PAUSE,
  SET_MP_PICTURE_INSTRUCTIONS,
  TOGGLE_FLAG_ALERTS,
};
